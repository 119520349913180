import Headings from "@narative/gatsby-theme-novela/src/components/Headings";
import Layout from "@narative/gatsby-theme-novela/src/components/Layout";
import Section from "@narative/gatsby-theme-novela/src/components/Section";
import SEO from "@narative/gatsby-theme-novela/src/components/SEO";
import Img from "gatsby-image";
import React from "react";

const headingText = { 
  main: "Thank you!",
  sub: "We really appreciate you sharing the love (and to celebrate Jakob is staying in the pool)."
} 


function Thanks({data}) {
  return (
    <Layout>
      <SEO />
      <Section>
      
        <div style={{ marginTop: "100px"}}>
          <Headings.h1>{headingText.main}</Headings.h1>
        </div>
        <div style={{ marginTop: "100px" }}>
          <Headings.h3>{headingText.sub}</Headings.h3>
        </div>
        
        <div style={{ marginTop: "75px" }}>
          <Img fluid={data.imageOne.childImageSharp.fluid} />
        </div>
        

      </Section>
    </Layout>
  );
}

export default Thanks;

export const pageQuery = graphql`
    query {
      imageOne: file(relativePath: { eq: "jakob-owens-qoFQxxuk3QY-unsplash.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 10000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
`;
